/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 4.0.0.
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/
.boxed h1 {
  font-size: 24px;
  color: #000;
  font-weight: bold;
}
.form-check-input:checked {
  background-color: #00a167 !important;
  border-color: #00a167 !important;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  border-color: gray;
}
.boxed h3 {
  font-size: 17px;
  color: #000;
  width: 150px;
  white-space: nowrap;
  display: flex;
}
.boxed h3 span {
  display: block;
  white-space: nowrap;
  position: absolute;
  left: 14%;
}
.classes.active {
    color: #00a167 !important;
    margin-bottom: 0px !important;
    border-bottom: 2px solid #1976d2 !important;
    padding-bottom: 6px;
}
.classes  {
    padding-bottom: 6px !important;
    margin-bottom: 0 !important;
    border-bottom: 2px solid transparent ;
}
.tabList p {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    position: relative;
    left: 0px;
    margin-right: 13px;
}
.tabList {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
}
.cards {
  background: #fff;
  padding: 15px 15px 23px;
  box-shadow: 0px 0px 10px #ccc;
  border-radius: 10px;
  margin: 0 28px 10px;
  position: relative;
}
.tables {
  padding: 0px;
  background: #fff;
  margin: 20px 20px 90px;
  border-radius: 10px;
  // box-shadow: 0px 0px 10px #ccc;
}
.MuiPagination-ul {
  justify-content: end !important;
  padding-top: 15px !important;
}
.tables table {
  background-color: #fff !important;
  box-shadow: 0px 0px 10px #ccc !important;
}
.tabList {
  display: flex;
  align-items: center;
}
.table-responsive.react-table table th {
  background: #fff !important;
}
.table-responsive.react-table table td {
  background: #fff !important;
}
.boxed img {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  margin: 14px 0;
}
.tables table th {
  border-bottom: 1px solid #ccc !important;
  font-weight: 600 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
.tables table td {
  border-bottom: 1px solid #ccc !important;
  font-weight: 600 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
.shortlogo {
  object-position: left;
  width: 22px;
  object-fit: cover;
  position: relative;
  left: 3px;
}
.modalText .MuiBox-root {
  width: 500px !important;
  padding: 20px !important;
  border: 0 !important;
  border-radius: 10px !important;
}
.cards span {
  font-weight: bold;
  padding: 6px;

  color: #151934;
  // box-shadow: 0px 0px 10px #000;
  position: absolute;
  right: 18px;
  top: 16px;
}
.cards h4 {
  font-weight: bold;
  color: #000;
  margin-bottom: 0;
}
.no-padding .col-md-4 {
  padding: 0 !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: rgb(0, 161, 103) !important;
  font-weight: bold !important;
  // justify-content: end !important;
  padding-bottom: 10px !important;
  align-items: center !important;
}
.muiTag {
  padding: 14px;
}
.muiTag h4 {
  color: rgb(0, 161, 103);
  font-size: 16px;
  margin-bottom: 0;
}
.muiTag .col-md-4 {
  padding-bottom: 12px;
}
.muiTag span {
  color: #000;
  font-size: 14px;
}
.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
  font-weight: bold !important;
  padding-bottom: 0 !important;
}
.boxed {
  background: #fff;
  margin: 100px 21px 20px;
  position: relative;

  padding: 16px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #ccc;
}
//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

@import "theme-light";
@import "theme-dark";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";
@import "custom/components/badge";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/calendar-full";
@import "custom/plugins/dragula";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/icons/fontawesome-all";
@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/timepicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/echarts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/file-manager";
@import "custom/pages/chat";
@import "custom/pages/projects";
@import "custom/pages/contacts";
@import "custom/pages/crypto";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";
@import "custom/pages/jobs";
